import React, { Component } from "react";
import { Link } from "gatsby";

import Layout from "../layouts/en";
import SEO from "../components/seo";
import langData from "../data/static-pages/pages/verify-success/en";


export default class VerifySuccessPage extends Component {
    constructor(props, context) {
        super(props, context);
        console.log(props)
    }
  
    render() {
      return (
        <Layout location={this.props.location}>
          <SEO title="Verify Success " keyword="Verify Success" decription="Verify Success" />
  
            <div className="reg container container--800">
                <h1 className="text--center">{langData.title}</h1>

                <p className="p--sm text--center mart--xxl marb--xxl">
                    {langData.description_1}<br/> {langData.description_2}
                </p>
            </div>
        </Layout>
      );
    }
  }
  